<template>
	<div class="mb-5">
		<!-- <b-card> -->
		<!-- <b-row>
        <b-col cols="12" md="4">
          <label for="example-datepicker">Company Name</label>
          <b-form-group>
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="8" class="">
          <b-row class="mb-2">
            <b-col md="4  mr-1">
              <label for="example-input">From Date</label>
              <b-form-datepicker
                id="from-date"
                placeholder="Choose a date"
                local="en"
                initial-date="2020-06-16"
              />
            </b-col>
            <b-col md="4 ">
              <label for="example-input">To Date</label>
              <b-form-datepicker
                id="to-date"
                placeholder="Choose a date"
                local="en"
                initial-date="2020-06-18"
              />
            </b-col>
            <b-col md="3 d-flex align-items-end">
              <b-button variant="primary "> Filter </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row> -->
		<!-- </b-card> -->
		<div v-if="infoData && infoData.length > 0">
			<div v-for="info in infoData" :key="info.id">
				<div class="row match-height mb-1">
					<div class="col-md-2 p-0">
						<div class="card card-congratulations">
							<div class="py-2 px-1 text-center">
								<b-img
									:src="require('@/assets/images/svg/hatchery/card-top.svg')"
									class="svg-img mb-1"
									style="height: 24px; width: 24px"
								/>
								<div class="text-center">
									<h6 class="font-small-2 text-white">Poultrac</h6>
									<h4 class="mb-1 text-white w-100">
										{{ info.hatchery_name || "NA" }}
									</h4>
								</div>

								<div class="d-flex flex-column justify-content-between">
									<div class="d-flex justify-content-between mt-1">
										<p class="card-text">No of Setter</p>
										<span class="font-weight-bold">{{ info.setterCount || "NA" }}</span>
									</div>
									<div class="d-flex justify-content-between">
										<p class="card-text">No of Hatcher</p>
										<span class="font-weight-bold">{{ info.hatcherCount || "NA" }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-3">
						<b-card>
							<div class="d-flex justify-content-between">
								<div class="d-flex justify-content-center flex-column">
									<div class="mb-1">Average Production</div>
									<h6 class="text-green text-center">{{ info.avg_production_percentage || "0" }}%</h6>
								</div>
								<div>
									<div class="mt-1 mb-2">
										<h6>Setter</h6>
										<h2 class="font-weight-bolder">
											{{ info.total_egg_input_format || "NA" }}
										</h2>
									</div>
									<div>
										<h6>Production</h6>
										<h2 class="font-weight-bolder">
											{{ info.total_chick_output_format || "NA" }}
										</h2>
									</div>
								</div>
							</div>
						</b-card>
					</div>
					<div class="col-lg-7 card card-statistics">
						<div class="card-header"></div>
						<div class="card-body statistics-body">
							<div class="row">
								<div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
									<div class="media">
										<div class="media-aside mr-2 align-self-start">
											<span class="b-avatar badge-light-primary rounded-circle" style="width: 48px; height: 48px"
												><span class="b-avatar-custom"
													><svg
														xmlns="http://www.w3.org/2000/svg"
														width="24px"
														height="24px"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
														class="feather feather-trending-up"
													>
														<polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
														<polyline points="17 6 23 6 23 12"></polyline></svg></span
												><!----></span
											>
										</div>
										<div class="media-body my-auto">
											<h4 class="font-weight-bolder mb-0">
												{{ info.total_chick_sale_format || "NA" }}
											</h4>
											<p class="card-text font-small-3 mb-0">Total Sales</p>
										</div>
									</div>
								</div>
								<div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
									<div class="media">
										<div class="media-aside mr-2 align-self-start">
											<span class="b-avatar badge-light-info rounded-circle" style="width: 48px; height: 48px"
												><span class="b-avatar-custom"
													><svg
														xmlns="http://www.w3.org/2000/svg"
														width="24px"
														height="24px"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
														class="feather feather-user"
													>
														<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
														<circle cx="12" cy="7" r="4"></circle></svg></span
												><!----></span
											>
										</div>
										<div class="media-body my-auto">
											<h4 class="font-weight-bolder mb-0">
												{{ info.total_egg_purchase_format || "NA" }}
											</h4>
											<p class="card-text font-small-3 mb-0">Total Purchased</p>
										</div>
									</div>
								</div>
								<div class="col-sm-6 col-xl-3 mb-2 mb-sm-0">
									<div class="media">
										<div class="media-aside mr-2 align-self-start">
											<span class="b-avatar badge-light-danger rounded-circle" style="width: 48px; height: 48px"
												><span class="b-avatar-custom"
													><svg
														xmlns="http://www.w3.org/2000/svg"
														width="24px"
														height="24px"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
														class="feather feather-box"
													>
														<path
															d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
														></path>
														<polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
														<line x1="12" y1="22.08" x2="12" y2="12"></line></svg></span
												><!----></span
											>
										</div>
										<div class="media-body my-auto">
											<h4 class="font-weight-bolder mb-0">
												{{ info.total_egg_purchase_format || "NA" }}
											</h4>
											<p class="card-text font-small-3 mb-0">Total Hatchability(%)</p>
										</div>
									</div>
								</div>
								<div class="col-sm-6 col-xl-3">
									<div class="media">
										<div class="media-aside mr-2 align-self-start">
											<span class="b-avatar badge-light-success rounded-circle" style="width: 48px; height: 48px"
												><span class="b-avatar-custom"
													><svg
														xmlns="http://www.w3.org/2000/svg"
														width="24px"
														height="24px"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														stroke-width="2"
														stroke-linecap="round"
														stroke-linejoin="round"
														class="feather feather-dollar-sign"
													>
														<line x1="12" y1="1" x2="12" y2="23"></line>
														<path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span
												><!----></span
											>
										</div>
										<div class="media-body my-auto">
											<h4 class="font-weight-bolder mb-0">
												{{ info.mortality || "NA" }}
											</h4>
											<p class="card-text font-small-3 mb-0">Mortality</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<b-card>
				<div class="d-flex top-header align-items-center">
					<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" style="cursor: pointer" />
					<div>
						<h5>Company Summary</h5>
					</div>
				</div>
				<div class="d-flex flex-column justify-content-center align-items-center py-5">
					<b-img :src="require('@/assets/images/svg/machine/no-setter.svg')" class="mr-2" />
					<h4 class="mt-2 Secondary">There is No Farm over there</h4>
				</div>
			</b-card>
		</div>
	</div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BCardTitle,
	BCardText,
	BCardBody,
	BRow,
	BCol,
	BMedia,
	BMediaAside,
	BAvatar,
	BMediaBody,
	BCarousel,
	BCarouselSlide,
	BButton,
	BImg,
	BFormDatepicker,
	BFormGroup,
} from "bootstrap-vue";

import vSelect from "vue-select";
import ChartjsLineChart from "../../charts-and-maps/charts/chartjs/ChartjsLineChart.vue";
import ApexDonutChart from "../../charts-and-maps/charts/apex-chart/ApexBarCircleChart.vue";
import ChartjsBarChart from "../../charts-and-maps/charts/chartjs/ChartjsBarChart.vue";
export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BButton,
		ChartjsLineChart,
		BFormDatepicker,
		ChartjsBarChart,
		BCarousel,
		BCarouselSlide,
		ApexDonutChart,
		BImg,
		BFormGroup,
		vSelect,
	},
	data() {
		return {
			infoData: [],
		};
	},

	created() {
		const id = this.$route.params.companyId;
		this.$http.get(`web/company/${id}/summary`).then((response) => {
			this.infoData = response.data;
		});
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
	},
};
</script>
<style lang="scss">
.carousel-inner {
	height: 200px !important;
}
.top-header {
	border-bottom: 1px solid #296db4;
	padding: 1rem 0rem;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/hatchery.scss";
@import "@core/scss/vue/pages/_common.scss";
</style>
